























import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
    name: "Aside"
})
export default class Aside extends Vue {

    @Prop({ default: false })
    protected isOpen!: boolean;

    @Prop({ default: "right" })
    protected position!: string;

    @Prop({ default: true })
    protected closable!: boolean;

    constructor() {
        super();
    }

    toggle() {
        this.$emit("asideMenuClose");
    }


    get getPosition(): string {
        return this.position + "-0";
    }

    get translateEnd(): string {
        return this.position === "left"
            ? "-translate-x-full ease-in"
            : "translate-x-full ease-in";
    }
}
