







































































import {Component, Vue} from "vue-property-decorator";
import {TrelloAPI} from "@/api/trello/TrelloAPI";
import Aside from "@/components/Aside.vue";
import {IndexDBClient} from "@/api/indexeddb/IndexDBClient";
import Card from "@/components/Card.vue";
import CardDetails from "@/components/CardDetails.vue";

@Component({
    name: "Dashboard",
    components:{Card, Aside, CardDetails}
})
export default class Dashboard extends Vue {
    protected isOpen = false;
    private api: TrelloAPI;
    private readonly database: IndexDBClient;
    private list = [];
    private filtered = 0;
    private member: any;
    private fullName = "";
    private cardIdSelected!: number;

    private inProgress = [];
    private cardsInProgress = []
    private onHold = [];
    private waitingReview = [];
    private completed = [];
    private totalCards = 0;
    private myCards = [];

    private details = "Loading Card Details";

    constructor() {
        super();
        this.api = new TrelloAPI();
        this.database = new IndexDBClient();
        this.$emit("hasMenu");
        // Getting all the cards for the chosen lists

       /* this.api.getCard("5eaa97ed7355765f09a23fe4").then( (card: any) => {
            console.log(card);
        })

        this.database.lists.then( (lists: any) => {
            lists.forEach( (list: any) => {
                console.log(`${list.name}`+" ==> "+`${list.id}`)
                if(list.status > 0) {
                    this.api.getCardsForList(`${list.id}`).then((cards: any) => {
                        console.log(`${list.name}` + ": " + cards.length);
                        cards.forEach((card: any) => {
                            //console.log(JSON.stringify(card))
                            this.database.cards = {
                                id: card.id,
                                idCard: card.idShort,
                                name: card.name,
                                desc: card.desc,
                                idList: card.idList,
                                idBoard: card.idBoard,
                                idChecklists: card.idChecklists,
                                idMembers: card.idMembers,
                                url: card.shortUrl,
                                dateLastActivity: card.dateLastActivity,
                                dueDate: card.dueDate,
                            }
                        })
                    }).catch((e: any) => {
                        console.log("getCardsForList( 59fb0d36c96d9f8b6bc47d80 ): " + e);
                    })
                }
            })
        })

        this.member = this.api.getMember().then( (member: any) => {
            return member;
        })

        this.cards.then( (cards: any) => {
            console.log(cards);
        })*/

    }

    get name(): string {
        // this.member.then( (member: any) => {
        //     this.fullName = member.fullName;
        // });
        return this.fullName;
    }

    get board() {
        return "";
    }

    get lists() {
        return "";
    }

    get cards() {
        //return Promise.resolve(
            //this.member.then((member: any) => {
                /*return this.database.cards.then((cards: any) => {
                    return cards.filter((card: any) => {
                        //console.log(member.id + " in " + JSON.stringify(card.idMembers))
                        //if (card.idMembers.includes(member.id)) {
                            return card;
                        //}
                    })
                })*/
           // })
        //)
      return [];
    }

    get numberOfCards() {
        // this.database.listsComplete.then( (lists: any) => {
        //     return lists.map( (list: any) => {return list.id});
        // }).then( (idList: any) => {
        //     return this.cards.then((cards: any) => {
        //         this.cards.then((cards: any) => {
        //             this.totalCards = cards.length;
        //         })
        //     })
        // })
        /*this.allNonCompletedCards.then( (cards: any) => {
            this.totalCards = cards.length;
        })
        return this.totalCards;*/
      return 0;
    }

    get cardsOverview() {
        /*this.allNonCompletedCards.then((cards: any) => {
            this.myCards = cards;
        })
        return this.myCards.slice(0, 4);*/
      return [];
    }

    get allNonCompletedCards() {
        /*return this.database.listsComplete.then( (lists: any) => {
            return lists.map( (list: any) => {return list.id});
        }).then( (idList: any) => {
            return this.cards.then( (cards: any) => {
                return cards.filter((card: any) => {return !idList.includes(card.idList)})
            }).then( (inProgressCards: any) => {
               return inProgressCards;
            })
        })*/
      return [];
    }

    get numberOfCardsInProgress(): number {
        /*this.database.listsInProgress.then( (lists: any) => {
            return lists.map( (list: any) => {return list.id});
        }).then( (idList: any) => {
            return this.cards.then( (cards: any) => {
                return cards.filter((card: any) => {return idList.includes(card.idList)})
            }).then( (inProgressCards: any) => {
                this.cardsInProgress = inProgressCards;
            })
        })
        return this.cardsInProgress.length;*/
      return 0;
    }

    get numberOfCardsOnHold(): number {
        /*this.database.listsOnHold.then( (lists: any) => {
            return lists.map( (list: any) => {return list.id});
        }).then( (idList: any) => {
            return this.cards.then( (cards: any) => {
                return cards.filter((card: any) => {return idList.includes(card.idList)})
            }).then( (inProgressCards: any) => {
                this.onHold = inProgressCards;
            })
        })
        return this.onHold.length;*/
      return 0;
    }

    get numberOfCardsInReview(): number {
        /*this.database.listsInReview.then( (lists: any) => {
            return lists.map( (list: any) => {return list.id});
        }).then( (idList: any) => {
            return this.cards.then( (cards: any) => {
                return cards.filter((card: any) => {return idList.includes(card.idList)})
            }).then( (inProgressCards: any) => {
                this.waitingReview = inProgressCards;
            })
        })
        return this.waitingReview.length;*/
      return 0;
    }

    get numberOfCardsCompleted(): number {
        /*this.database.listsComplete.then( (lists: any) => {
            return lists.map( (list: any) => {return list.id});
        }).then( (idList: any) => {
            return this.cards.then( (cards: any) => {
                return cards.filter((card: any) => {return idList.includes(card.idList)})
            }).then( (inProgressCards: any) => {
                this.completed = inProgressCards;
            })
        })
        return this.completed.length;*/
      return 0;
    }

    showCardDetails(id: any) {
        this.setDetails(""+id);
        if(this.cardIdSelected && this.cardIdSelected == id)
        {
            this.isOpen = !this.isOpen;
        }
        else {
            this.cardIdSelected = id;
            this.isOpen = true;
        }


    }

    setDetails(details: string) {
        this.details = details;
    }

    get cardDetails(): string {
        const d = this.details;
        return d;
    }

    openCloseMenu() {
        this.isOpen = !this.isOpen;
    }

    isSelected(id: number): boolean {
        const value = this.cardIdSelected == id;
        return value;
    }

}
